<template>
  <div class="file-container">
    <h3 class="file-container__title">{{title}}</h3>
    <div class="file-upload__input-container">
      <input type="file" ref="fileInput" @change="handleFileChange" multiple>
      <button v-on:click="uploadFiles" class="button button-primary" id="plantegninger_file-button" :disabled="isUploadDisabled">
        Upload
      </button>
    </div>
    <div v-if="loading" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    <div v-if="uploadError" class="file-upload__error">
      <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
    </div>
    <ul class="file-upload__list">
      <li v-for="(file, key) in reversedFiles" :key="key" class="file-upload__item">
        <a :href="file.url" target="_blank"></a>
        <a data-toggle="tooltip" :title="file.name" v-bind:href="file.url" target="_blank" class="button button-secondary file-upload__name">
          <img src="@/assets/icon-download.svg" alt="Download">
          <span>{{file.name}}</span>
        </a>
        <button v-on:click="openDeleteModal(file, field)" class="file-upload__delete-btn">
          <img src="@/assets/icon-trash.svg" alt="Delete" class="file-upload__delete-image">
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FileUpload',
  props: {
    field: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    openDeleteModal: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      fileInputHasValue: false,
      uploadError: false
    }
  },
  computed: {
    isUploadDisabled () {
      return !this.fileInputHasValue || this.loading
    },
    reversedFiles () {
      return this.files.slice().reverse()
    }
  },
  methods: {
    async uploadFiles () {
      const field = this.field
      if (!this.$refs.fileInput.files.length) {
        return
      }
      this.loading = true
      const filesArray = [...this.$refs.fileInput.files]
      const newFiles = []
      for (const file of filesArray) {
        const formData = new FormData()
        formData.append('file', file)
        try {
          const fileIOResponse = await axios.post('https://file.io/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Basic NUVRRURUWC5SS1RDWUQxLUo1MU1YTkEtUFk0VkFaMS1CSkRFMktFOg=='
            }
          })
          const payload = {
            url: fileIOResponse.data.link,
            name: file.name,
            key: fileIOResponse.data.key,
            field
          }
          const response = await axios.post(`/api/projects/${this.$route.params.id}/files`, payload)
          newFiles.push(response.data)
        } catch (error) {
          console.log(error)
          this.uploadError = true
        }
      }
      this.loading = false
      this.$emit('add-files', field, newFiles)
      this.$refs.fileInput.value = ''
      this.fileInputHasValue = false
    },
    handleFileChange (event) {
      this.fileInputHasValue = event.target.files.length > 0
      this.uploadError = false
    }
  }
}
</script>

<style lang="scss" scoped>

  .file-container {
      margin-bottom: 48px;
      .file-container__title {
        font-weight: 600;
        margin-bottom: 4px;
      }

      .file-upload__input-container {
        display: flex;
        margin-bottom: 20px;
        input[type="file"] {
          margin-right: 12px;
          &::file-selector-button {
            margin-right: 12px;
            background-color: $gray-20;
            font-family: inherit;
            font-size: 12px;
            font-weight: 600;
            padding: 4px 8px;
            border-radius: 4px;
            border: none;
            cursor: pointer;
            &:hover {
              background-color: $gray-30;
            }
          }
        }
      }
      .file-upload__name {
        text-overflow: ellipsis;
        width: 250px;
        overflow: hidden;
        white-space: nowrap;
      }

      .file-upload__delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        min-width: 55px;
        margin-left: 12px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid #00352F;
        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }
      .file-upload__delete-image {
        width: 20px;
        height: 20px;
      }
      .file-upload__item {
        display: flex;
        &:not(:last-child){
          margin-bottom: 12px;
        }
      }
      .file-upload__error {
        max-width: 550px;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 8px;
        background: $red-10;
        p {
          margin-bottom: 0;
          strong {
            display: block;
            font-weight: 600;
            color: $red-50;
          }
        }
      }
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 50px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 19px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $green-70;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 24px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 42px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(18px, 0);
    }
  }
</style>
