<template>
  <div class="project">

    <div class="project-sidebar" v-bind:class="{ open: menuOpen }">
      <div class="project-sidebar-header">
        <div class="project-sidebar-logo">
          <img src="@/assets/logo.svg" alt="Propstep">
          <h3 class="heading-xxs">Onboard</h3>
        </div>
        <div class="project-sidebar-burger" v-on:click="toggleMenu"></div>
      </div>
      <div id="sections" class="project-sidebar-nav">
        <h3>Påkrævet</h3>
        <ul id="requiredSections">
          <li><a href="#" v-bind:class="{ active: activeSection == 'projektnavn', complete: project.fields.projektnavn_name != '' }" v-on:click="switchSection('projektnavn', $event)">Grundlæggende info</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'boligliste', complete: project.fields.boligliste_files && project.fields.boligliste_files.length > 0 || project.fields.boligliste_file != ''}" v-on:click="switchSection('boligliste', $event)">Boligliste</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'billeder', complete: project.fields.billeder_files && project.fields.billeder_files.length > 0 || project.fields.billeder_file != '' }" v-on:click="switchSection('billeder', $event)">Billeder</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'plantegninger', complete: project.fields.plantegninger_files && project.fields.plantegninger_files.length > 0 || project.fields.plantegninger_file != '' }" v-on:click="switchSection('plantegninger', $event)">Plantegninger</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'projektbeskrivelse', complete: project.fields.projektbeskrivelse_text != '' }" v-on:click="switchSection('projektbeskrivelse', $event)">Projektbeskrivelse</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'boligbeskrivelse', complete: project.fields.boligbeskrivelse_text != '' }" v-on:click="switchSection('boligbeskrivelse', $event)">Boligbeskrivelse</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'lejekontrakt', complete: project.fields.lejekontrakt_files && project.fields.lejekontrakt_files.length > 0 || project.fields.lejekontrakt_file != '' }" v-on:click="switchSection('lejekontrakt', $event)">Lejekontrakt</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'faktura', complete: project.fields.faktura_cvr != '' && project.fields.faktura_company != '' && project.fields.faktura_email != '' }" v-on:click="switchSection('faktura', $event)">Faktura</a></li>
        </ul>
        <h3>Valgfri</h3>
        <ul>
          <li><a href="#" v-bind:class="{ active: activeSection == 'isometri', complete: project.fields.isometri_files && project.fields.isometri_files.length > 0 || project.fields.isometri_file != '' }" v-on:click="switchSection('isometri', $event)">Isometri</a></li>
          <li><a href="#" v-bind:class="{ active: activeSection == 'materialeliste', complete: project.fields.materialeliste_files && project.fields.materialeliste_files.length > 0 || project.fields.materialeliste_file != '' }" v-on:click="switchSection('materialeliste', $event)">Materialeliste</a></li>
        </ul>
      </div>
      <div class="project-sidebar-footer">
        <button class="button button-secondary button-white" v-on:click="showSendModal">Indsend...</button>
      </div>
    </div>

    <div class="project-header" v-if="!loading">
      <div class="project-header-name">
        <h1 class="heading-xs">{{ project.name }}</h1>
      </div>
      <div class="project-header-save">
        <p v-if="unsavedChanges && !isSaving">Der er nye ændringer</p>
        <p v-if="unsavedChanges && isSaving"><img src="@/assets/loading.gif" alt="Saving">Gemmer</p>
        <p v-if="!unsavedChanges && !isSaving && changesMade"><img src="@/assets/icon-saved.svg" alt="Saved">Alle ændringer gemt</p>
      </div>
    </div>

    <div class="project-content" v-if="!loading">
      <div class="project-content-wrapper">

        <div class="project-content-section" v-if="activeSection == 'projektnavn'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Grundlæggende info</h2>
            <p>Velkommen til onboarding af jeres projekt/ejendom på Propstep. Udfyld venligst al den krævede information og upload det nødvendige materiale og indsend herefter til Propstep, så sætter vi gang i oprettelsen.</p>
            <p>Du kan indsende igen, hvis du laver ændringer samt benytte kommentarfeltet under hvert punkt til uddybende oplysninger.</p>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Navn på projekt/ejendom</h3>
              <p>Det ønskede navn på projektet/ejendommen</p>
              <input type="text" id="projektnavn_nameInput" v-model="project.fields.projektnavn_name" v-on:input="nameChanged($event); inputUpdated();" placeholder="Projektnavn">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Dato for prehype (Valgfri)</h3>
              <p>Hvornår kan vi opstarte opbygning af interesse og omtale af projektet?</p>
              <input type="text" v-model="project.fields.projektnavn_prehype" v-on:input="inputUpdated" placeholder="fx. 01/01/2022">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Dato for udlejning (Valgfri)</h3>
              <p>Hvornår starter udlejningen?</p>
              <input type="text" v-model="project.fields.projektnavn_rental" v-on:input="inputUpdated" placeholder="fx. 01/01/2022">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Dato for indflytning (Valgfri)</h3>
              <p>Hvornår sker indflytningen fra?</p>
              <input type="text" v-model="project.fields.projektnavn_movein" v-on:input="inputUpdated" placeholder="fx. 01/01/2022">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.projektnavn_comments"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-primary" v-on:click="switchSection('boligliste', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'boligliste'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Boligliste</h2>
            <p>Download nedenstående Excel-skabelon og udfyld den med så meget boligdata som muligt. Upload herefter den udfyldte excelfil nedenfor.</p>
            <a href="/files/Propstep-Skabelon.xlsx" download class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download Excel-skabelon</a>
          </div>
          <div class="project-content-section-field">
            <FileUpload
              :key="'boligliste_files'"
              v-if="project.supportMultipleFiles"
              :openDeleteModal="openDeleteModal"
              title="Boligliste (Excel-fil)"
              field="boligliste_files"
              :files="project.fields.boligliste_files"
              @add-files="handleAddFiles"
            />
            <div v-else :key="'boligliste_file'" >
              <h3>Boligliste (Excel-fil)</h3>
              <div class="file-upload">
                <div class="file-upload-input">
                  <input type="file" v-on:change="changeFile('boligliste_file', $event)" id="boligliste_file">
                  <button v-on:click="uploadFile('boligliste_file', $event)" class="button button-primary" id="boligliste_file-button" disabled>Upload</button>
                </div>
                <div class="file-upload-error" id="boligliste_file-error">
                  <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
                </div>
                <div class="file-upload-success" id="boligliste_file-success">
                  <p><strong>Filen blev uploadet</strong>Du kan uploade en ny fil igen for at overskrive nedenstående.</p>
                </div>
                <div class="file-upload-button">
                  <input type="text" id="boligliste_file-input" v-model="project.fields.boligliste_file" disabled>
                  <a v-if="project.fields.boligliste_file != ''" v-bind:href="project.fields.boligliste_file" target="_blank" class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download filen</a>
                </div>
              </div>
            </div>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.boligliste_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('projektnavn', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('billeder', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'billeder'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Billeder af projekt &amp; bolig</h2>
            <p>Den visuelle repræsentation af jeres projekt/ejendom og boliger er meget vigtig. Hvad end det er fotos eller renderinger, så skal der som udgangspunkt være mindst ét billede til både projekt og bolig.</p>
            <p>Bredformat fungerer bedst, men har I noget i højformat så send dem også gerne med. Vi vil gerne have billeder i så stor størrelse og høj opløsning som muligt. Leveres i JPG format. Minimum 1500 x 739 pixels.</p>
          </div>
          <div class="project-content-section-field">
            <FileUpload
              :key="'billeder_files'"
              v-if="project.supportMultipleFiles"
              :openDeleteModal="openDeleteModal"
              title="Billeder (ZIP-fil)"
              field="billeder_files"
              :files="project.fields.billeder_files"
              @add-files="handleAddFiles"
            />
            <div v-else :key="'billeder_file'">
              <h3>Billeder (ZIP-fil)</h3>
              <div class="file-upload">
                <div class="file-upload-input">
                  <input type="file" v-on:change="changeFile('billeder_file', $event)" id="billeder_file">
                  <button v-on:click="uploadFile('billeder_file', $event)" class="button button-primary" id="billeder_file-button" disabled>Upload</button>
                </div>
                <div class="file-upload-error" id="billeder_file-error">
                  <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
                </div>
                <div class="file-upload-success" id="billeder_file-success">
                  <p><strong>Filen blev uploadet</strong>Du kan uploade en ny fil igen for at overskrive nedenstående.</p>
                </div>
                <div class="file-upload-button">
                  <input type="text" id="billeder_file-input" v-model="project.fields.billeder_file" disabled>
                  <a v-if="project.fields.billeder_file != ''" v-bind:href="project.fields.billeder_file" target="_blank" class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download filen</a>
                </div>
              </div>
            </div>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.billeder_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('boligliste', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('plantegninger', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'plantegninger'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Plantegninger</h2>
            <p>Plantegninger er helt afgørende for at give den boligsøgende det rigtige indtryk af boligen. Vi skal derfor altid bruge en plantegning til hver enkelt bolig.</p>
            <p>Vi anbefaler, at man:
              <ul>
                <li>Får sin arkitekt eller bureau til at levere de færdige plantegninger uden for meget "støj" i form af tekst og ikoner</li>
                <li>Angiver mål (længder) på alle rum, men ikke m2 angivelser</li>
                <li>Angiver nord med en pil</li>
              </ul>
            </p>
            <p>Leveres i JPG format. Minimum 1500 x 739 pixels.</p>
          </div>
          <div class="project-content-section-field">
            <FileUpload
              :key="'plantegninger_files'"
              v-if="project.supportMultipleFiles"
              :openDeleteModal="openDeleteModal"
              title="Plantegninger (ZIP-fil)"
              field="plantegninger_files"
              :files="project.fields.plantegninger_files"
              @add-files="handleAddFiles"
            />
            <div v-else :key="'plantegninger_file'">
              <h3>Plantegninger (ZIP-fil)</h3>
              <div class="file-upload">
                <div class="file-upload-input">
                  <input type="file" v-on:change="changeFile('plantegninger_file', $event)" id="plantegninger_file">
                  <button v-on:click="uploadFile('plantegninger_file', $event)" class="button button-primary" id="plantegninger_file-button" disabled>Upload</button>
                </div>
                <div class="file-upload-error" id="plantegninger_file-error">
                  <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
                </div>
                <div class="file-upload-success" id="plantegninger_file-success">
                  <p><strong>Filen blev uploadet</strong>Du kan uploade en ny fil igen for at overskrive nedenstående.</p>
                </div>
                <div class="file-upload-button">
                  <input type="text" id="plantegninger_file-input" v-model="project.fields.plantegninger_file" disabled>
                  <a v-if="project.fields.plantegninger_file != ''" v-bind:href="project.fields.plantegninger_file" target="_blank" class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download filen</a>
                </div>
              </div>
            </div>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.plantegninger_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('billeder', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('projektbeskrivelse', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'projektbeskrivelse'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Projektbeskrivelse</h2>
            <p>Vi skal bruge en tekst omkring selve projektet/ejendommen på 200-500 ord.</p>
            <p>Inddel gerne i sektioner med underoverskrifter.</p>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Projektbeskrivelse dansk</h3>
              <textarea class="big" v-model="project.fields.projektbeskrivelse_text" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Projektbeskrivelse engelsk (Valgfri)</h3>
              <textarea class="big" v-model="project.fields.projektbeskrivelse_text_en" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.projektbeskrivelse_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('plantegninger', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('boligbeskrivelse', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'boligbeskrivelse'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Boligbeskrivelse</h2>
            <p>Vi skal bruge en beskrivelse af boligerne. Det kan være samme tekst til alle boliger eller opdelt på typer. Længde: 100-400 ord.</p>
            <p>Inddel evt. i sektioner med underoverskrifter.</p>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Boligbeskrivelse dansk</h3>
              <textarea class="big" v-model="project.fields.boligbeskrivelse_text" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Boligbeskrivelse engelsk (Valgfri)</h3>
              <textarea class="big" v-model="project.fields.boligbeskrivelse_text_en" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.boligbeskrivelse_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('projektbeskrivelse', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('isometri', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'isometri'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Isometri</h2>
            <p>Til opsætning af jeres isometri/boligvælger skal vi bruge en fil for hver vinkel med følgende krav:
              <ul>
                <li>PDF eller SVG format</li>
                <li>Minimum 1500 pixel i bredden</li>
                <li>Boliger indtegnet med sammenhængende paths, navngivet med bolignummer eller adresse</li>
                <li>Bolignummer/husnummer på hver bolig indsat som tekst</li>
                <li>Så komprimeret filstørrelse som muligt. Normalt under 500kb.</li>
              </ul>
            </p>
            <p>Isometrien vil fremstå præcis som den fil i fremsender, hvor vi har lagt et lag med farve (rød, gul, grøn) indover.</p>
            <p>Tips til en optimal isometri:
              <ul>
                <li>En simpel stregmodel uden farver eller andet unødigt støj fungerer bedst</li>
                <li>Udnyt den fulde bredde, da boligerne ellers kan blive meget små i det færdige resultat</li>
                <li>Angiv nord med en pil</li>
              </ul>
            </p>
          </div>
          <div class="project-content-section-field">
            <FileUpload
              :key="'isometri_files'"
              v-if="project.supportMultipleFiles"
              :openDeleteModal="openDeleteModal"
              title="Isometri (SVG/ZIP-fil)"
              field="isometri_files"
              :files="project.fields.isometri_files"
              @add-files="handleAddFiles"
            />
            <div v-else :key="'isometri_file'">
              <h3>Isometri (SVG/ZIP-fil)</h3>
              <div class="file-upload">
                <div class="file-upload-input">
                  <input type="file" v-on:change="changeFile('isometri_file', $event)" id="isometri_file">
                  <button v-on:click="uploadFile('isometri_file', $event)" class="button button-primary" id="isometri_file-button" disabled>Upload</button>
                </div>
                <div class="file-upload-error" id="isometri_file-error">
                  <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
                </div>
                <div class="file-upload-success" id="isometri_file-success">
                  <p><strong>Filen blev uploadet</strong>Du kan uploade en ny fil igen for at overskrive nedenstående.</p>
                </div>
                <div class="file-upload-button">
                  <input type="text" id="isometri_file-input" v-model="project.fields.isometri_file" disabled>
                  <a v-if="project.fields.isometri_file != ''" v-bind:href="project.fields.isometri_file" target="_blank" class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download filen</a>
                </div>
              </div>
            </div>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.isometri_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('boligbeskrivelse', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('lejekontrakt', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'lejekontrakt'">
          <div class="project-content-section-intro">
            <label>
              <h2 class="heading-r">Lejekontrakt &amp; vedhæftninger</h2>
              <p>Vi skal bruge en redigérbar udgave af typeformular A10 udfyldt med den information som går igen for alle boliger samt eventuelle vedhæftninger.</p>
            </label>
          </div>
          <div class="project-content-section-field">
            <FileUpload
              :key="'lejekontrakt_files'"
              v-if="project.supportMultipleFiles"
              :openDeleteModal="openDeleteModal"
              title="Kontrakt-filer (ZIP-fil)"
              field="lejekontrakt_files"
              :files="project.fields.lejekontrakt_files"
              @add-files="handleAddFiles"
            />
            <div v-else :key="'lejekontrakt_file'">
              <h3>Kontrakt-filer (ZIP-fil)</h3>
              <div class="file-upload">
                <div class="file-upload-input">
                  <input type="file" v-on:change="changeFile('lejekontrakt_file', $event)" id="lejekontrakt_file">
                  <button v-on:click="uploadFile('lejekontrakt_file', $event)" class="button button-primary" id="lejekontrakt_file-button" disabled>Upload</button>
                </div>
                <div class="file-upload-error" id="lejekontrakt_file-error">
                  <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
                </div>
                <div class="file-upload-success" id="lejekontrakt_file-success">
                  <p><strong>Filen blev uploadet</strong>Du kan uploade en ny fil igen for at overskrive nedenstående.</p>
                </div>
                <div class="file-upload-button">
                  <input type="text" id="lejekontrakt_file-input" v-model="project.fields.lejekontrakt_file" disabled>
                  <a v-if="project.fields.lejekontrakt_file != ''" v-bind:href="project.fields.lejekontrakt_file" target="_blank" class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download filen</a>
                </div>
              </div>
            </div>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.lejekontrakt_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('isometri', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('materialeliste', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'materialeliste'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Materialeliste</h2>
            <p>Upload en liste med al indvendig og udvendig inventar og materialer herunder leverandører/producenter.</p>
            <p>Vær gerne så specifik som mulig, så vi kan informere lejeren bedst muligt.</p>
          </div>
          <div class="project-content-section-field">
            <FileUpload
              :key="'materialeliste_files'"
              v-if="project.supportMultipleFiles"
              :openDeleteModal="openDeleteModal"
              title="Materialeliste (PDF/ZIP-fil)"
              field="materialeliste_files"
              :files="project.fields.materialeliste_files"
              @add-files="handleAddFiles"
            />
            <div v-else :key="'materialeliste_file'">
              <h3>Materialeliste (PDF/ZIP-fil)</h3>
              <div class="file-upload">
                <div class="file-upload-input">
                  <input type="file" v-on:change="changeFile('materialeliste_file', $event)" id="materialeliste_file">
                  <button v-on:click="uploadFile('materialeliste_file', $event)" class="button button-primary" id="materialeliste_file-button" disabled>Upload</button>
                </div>
                <div class="file-upload-error" id="materialeliste_file-error">
                  <p><strong>Upload fejl</strong>Prøv venligst igen. Husk at filen må max være 10 GB i størrelse.</p>
                </div>
                <div class="file-upload-success" id="materialeliste_file-success">
                  <p><strong>Filen blev uploadet</strong>Du kan uploade en ny fil igen for at overskrive nedenstående.</p>
                </div>
                <div class="file-upload-button">
                  <input type="text" id="materialeliste_file-input" v-model="project.fields.materialeliste_file" disabled>
                  <a v-if="project.fields.materialeliste_file != ''" v-bind:href="project.fields.materialeliste_file" target="_blank" class="button button-secondary"><img src="@/assets/icon-download.svg" alt="Download">Download filen</a>
                </div>
              </div>
            </div>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.materialeliste_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('lejekontrakt', $event)">Forrige</button>
            <button class="button button-primary" v-on:click="switchSection('faktura', $event)">Næste</button>
          </div>
        </div>

        <div class="project-content-section" v-if="activeSection == 'faktura'">
          <div class="project-content-section-intro">
            <h2 class="heading-r">Oplysninger til faktura</h2>
            <p>Udfyld venligst hvilket selskab skal vi fakturere og hvor skal fakturaer sendes til.</p>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>CVR nr.</h3>
              <input type="text" v-model="project.fields.faktura_cvr" v-on:input="inputUpdated" placeholder="fx. 19374562">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Firmanavn</h3>
              <input type="text" v-model="project.fields.faktura_company" v-on:input="inputUpdated" placeholder="fx. Firma Navn">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Email adresse</h3>
              <p>Adressen som skal modtage fakturaer</p>
              <input type="email" v-model="project.fields.faktura_email" v-on:input="inputUpdated" placeholder="fx. email@firmanavn.dk">
            </label>
          </div>
          <div class="project-content-section-field">
            <label>
              <h3>Kommentarer (Valgfri)</h3>
              <p>Har du kommentarer til det indsendte eller er der andet vi skal vide?</p>
              <textarea v-model="project.fields.faktura_comments" v-on:input="inputUpdated"></textarea>
            </label>
          </div>
          <div class="project-content-section-footer">
            <button class="button button-secondary" v-on:click="switchSection('materialeliste', $event)">Forrige</button>
          </div>
        </div>

      </div>
    </div>

    <div class="project-loading" v-else>
      <img src="@/assets/loading.gif" alt="Loading">
    </div>

    <div class="project-success" v-if="success">
      <div class="project-success-inner">
        <div class="project-success-animation">
          <vLottiePlayer name="success" :animationData="require('@/assets/success.json')"/>
        </div>
        <h3 class="heading-xs">Indsendt til Propstep</h3>
        <p>{{ project.name }} er nu blevet indsent og vi sætter nu gang i oprettelsen!</p>
      </div>
    </div>

    <div class="modal" v-if="sendModal">
      <div class="modal-box">
        <h2 class="heading-xs">Er du sikker?</h2>
        <p>Bekræft venligst at du gerne vil indsende dette projekt og give besked til Propstep</p>
        <div class="modal-box-warning" v-if="requiredWarning">
          <p><strong>Bemærk:</strong> Alle de påkrævet sektioner er ikke blevet udfyldt endnu!</p>
        </div>
        <button class="button button-primary" v-on:click="sendEmail">Indsend til Propstep</button>
        <span class="modal-box-close" v-on:click="hideSendModal"></span>
      </div>
    </div>
    <div class="modal" v-if="showDeleteModal">
      <div class="modal-box">
        <h2 class="heading-xs">Er du sikker?</h2>
        <p>Bekræft venligst, at du ønsker at slette denne fil</p>
        <button class="button button-danger delete-modal__delete-btn" v-on:click="deleteFile">Slet fil</button>
        <button class="button button-secondary" v-on:click="hideDeleteModal">Fortryd</button>
        <span class="modal-box-close" v-on:click="hideDeleteModal"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueLottiePlayer from 'vue-lottie-player'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'Project',
  components: {
    vLottiePlayer: VueLottiePlayer,
    FileUpload
  },
  data () {
    return {
      loading: true,
      project: {
        fields: {}
      },
      activeSection: 'projektnavn',
      sendModal: false,
      changesMade: false,
      unsavedChanges: false,
      isSaving: false,
      success: false,
      requiredWarning: true,
      menuOpen: false,
      showDeleteModal: false,
      fileToDelete: null,
      imageField: null
    }
  },
  methods: {
    hideDeleteModal () {
      this.showDeleteModal = false
      this.fileToDelete = null
    },
    openDeleteModal (file, field) {
      this.showDeleteModal = true
      this.fileToDelete = file
      this.imageField = field
    },
    async deleteFile () {
      try {
        await axios.post(`/api/projects/${this.$route.params.id}/delete-file/${this.fileToDelete.key}`, { field: this.imageField })
        const index = this.project.fields[this.imageField].indexOf(this.fileToDelete)
        this.project.fields[this.imageField].splice(index, 1)
        this.showDeleteModal = false
        this.fileToDelete = null
      } catch (error) {
        console.log(error)
      }
    },
    handleAddFiles (field, newFiles) {
      this.project.fields[field].push(...newFiles)
    },
    getProject: function () {
      const self = this
      self.loading = true
      axios.get('/api/projects/' + self.$route.params.id).then(function (response) {
        self.project = response.data
        self.loading = false
        document.title = self.project.name + ' | Propstep Onboard'
      }).catch(function () {
        self.$router.push('/').catch(() => {})
      })
    },
    updateProject: function () {
      const self = this
      self.isSaving = true
      axios.post('/api/projects/update', {
        id: self.project._id,
        name: self.project.fields.projektnavn_name,
        projektnavn_name: self.project.fields.projektnavn_name,
        projektnavn_prehype: self.project.fields.projektnavn_prehype,
        projektnavn_rental: self.project.fields.projektnavn_rental,
        projektnavn_movein: self.project.fields.projektnavn_movein,
        projektnavn_comments: self.project.fields.projektnavn_comments,
        boligliste_file: self.project.fields.boligliste_file,
        boligliste_comments: self.project.fields.boligliste_comments,
        billeder_file: self.project.fields.billeder_file,
        billeder_comments: self.project.fields.billeder_comments,
        plantegninger_file: self.project.fields.plantegninger_file,
        plantegninger_comments: self.project.fields.plantegninger_comments,
        projektbeskrivelse_text: self.project.fields.projektbeskrivelse_text,
        projektbeskrivelse_text_en: self.project.fields.projektbeskrivelse_text_en,
        projektbeskrivelse_comments: self.project.fields.projektbeskrivelse_comments,
        boligbeskrivelse_text: self.project.fields.boligbeskrivelse_text,
        boligbeskrivelse_text_en: self.project.fields.boligbeskrivelse_text_en,
        boligbeskrivelse_comments: self.project.fields.boligbeskrivelse_comments,
        isometri_file: self.project.fields.isometri_file,
        isometri_comments: self.project.fields.isometri_comments,
        lejekontrakt_file: self.project.fields.lejekontrakt_file,
        lejekontrakt_comments: self.project.fields.lejekontrakt_comments,
        materialeliste_file: self.project.fields.materialeliste_file,
        materialeliste_comments: self.project.fields.materialeliste_comments,
        faktura_cvr: self.project.fields.faktura_cvr,
        faktura_company: self.project.fields.faktura_company,
        faktura_email: self.project.fields.faktura_email,
        faktura_comments: self.project.fields.faktura_comments,
        boligliste_files: self.project.fields.boligliste_files,
        billeder_files: self.project.fields.billeder_files,
        plantegninger_files: self.project.fields.plantegninger_files,
        isometri_files: self.project.fields.isometri_files,
        lejekontrakt_files: self.project.fields.lejekontrakt_files,
        materialeliste_files: self.project.fields.materialeliste_files
      }).then(function () {
        setTimeout(function () {
          self.isSaving = false
          self.unsavedChanges = false
        }, 1000)
      })
    },
    nameChanged: function (event) {
      const self = this
      self.project.name = self.project.fields.projektnavn_name
      document.title = self.project.name + ' | Propstep Onboard'
      if (event.target.value === '') {
        self.project.fields.projektnavn_name = self.project.name
      }
    },
    inputUpdated: function () {
      this.changesMade = true
      this.unsavedChanges = true
    },
    switchSection: function (section, event) {
      event.preventDefault()
      const self = this
      self.success = false
      self.activeSection = section
      document.body.scrollTop = document.documentElement.scrollTop = 0
      self.menuOpen = false
    },
    showSendModal: function () {
      this.sendModal = true
      this.menuOpen = false
      if (this.unsavedChanges) {
        this.updateProject()
      }
      const required = document.getElementById('requiredSections').getElementsByClassName('complete')
      if (required.length === 6) {
        this.requiredWarning = false
      } else {
        this.requiredWarning = true
      }
    },
    hideSendModal: function () {
      this.sendModal = false
    },
    toggleMenu: function () {
      if (this.menuOpen) {
        this.menuOpen = false
      } else {
        this.menuOpen = true
      }
    },
    changeFile: function (field, event) {
      document.getElementById(field + '-button').disabled = false
      document.getElementById(field + '-error').style.display = 'none'
      document.getElementById(field + '-success').style.display = 'none'
    },
    uploadFile: function (field, event) {
      const self = this
      const formData = new FormData()
      document.getElementById(field + '-button').disabled = true
      document.getElementById(field + '-button').innerHTML = 'Uploader'
      formData.append('file', document.getElementById(field).files[0])
      axios.post('https://file.io/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Basic NUVRRURUWC5SS1RDWUQxLUo1MU1YTkEtUFk0VkFaMS1CSkRFMktFOg=='
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          document.getElementById(field + '-button').innerHTML = 'Uploader (' + percentCompleted + '%)'
        }
      }).then(function (response) {
        document.getElementById(field + '-success').style.display = 'block'
        document.getElementById(field + '-input').value = response.data.link
        const event = new Event('input', {
          bubbles: true,
          cancelable: true
        })
        document.getElementById(field + '-button').innerHTML = 'Upload'
        document.getElementById(field + '-input').dispatchEvent(event)
        document.getElementById(field).value = null
        self.updateProject()
      }).catch(function () {
        document.getElementById(field + '-error').style.display = 'block'
        const event = new Event('input', {
          bubbles: true,
          cancelable: true
        })
        document.getElementById(field + '-button').innerHTML = 'Upload'
        document.getElementById(field + '-input').dispatchEvent(event)
        document.getElementById(field).value = null
      })
    },
    sendEmail: function () {
      const self = this
      self.sendModal = false
      self.loading = true
      axios.post('/api/email/send', {
        name: this.project.name,
        pid: this.project.pid
      }).then(function () {
        self.activeSection = 'success'
        self.success = true
        self.changesMade = false
        self.loading = false
      })
    }
  },
  created () {
    const self = this
    self.getProject()
    setInterval(function () {
      if (self.unsavedChanges) {
        self.updateProject()
      }
    }, 8000)
  }
}
</script>

<style lang="scss" scoped>
.project {
  position: relative;
  &-sidebar {
    width: 100%;
    height: 64px;
    color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: $green-80;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    @media only screen and (min-width: $large) {
      width: 300px;
      height: 100vh;
    }
    &.open {
      height: 100vh;
      .project-sidebar-burger {
        background-image: url("../assets/icon-burger-close.svg");
      }
    }
    &-header {
      flex: 0 0 auto;
      padding: 17px 24px;
      position: relative;
      @media only screen and (min-width: $large) {
        padding: 27px 32px;
      }
    }
    &-logo {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
      }
      h3 {
        color: white;
        margin-left: 8px;
      }
    }
    &-burger {
      width: 44px;
      height: 44px;
      background-image: url("../assets/icon-burger.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 44px 44px;
      position: absolute;
      top: 10px;
      right: 24px;
      @media only screen and (min-width: $large) {
        display: none;
      }
    }
    &-nav {
      flex: 1 1 100%;
      overflow: auto;
      margin-top: 20px;
      h3 {
        font-size: 12px;
        font-weight: 600;
        padding: 0 32px;
        margin-bottom: 4px;
      }
      ul {
        margin-bottom: 24px;
        li {
          a {
            font-weight: 600;
            color: $gray-50;
            padding: 8px 28px 8px 52px;
            border-left: 4px solid $green-80;
            display: block;
            position: relative;
            &:before {
              content: "";
              width: 16px;
              height: 16px;
              border: 1px solid $gray-50;
              border-radius: 100%;
              position: absolute;
              top: 50%;
              left: 28px;
              transform: translateY(-50%);
            }
            &:hover {
              background: $green-90;
              border-color: $green-90;
            }
            &.active {
              color: white;
              border-color: white;
              &:before {
                border-color: white;
              }
            }
            &.complete {
              &:before {
                background-color: $green-50;
                background-image: url("../assets/icon-check.svg");
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 8px 6px;
                border-color: $green-50;
              }
            }
          }
        }
      }
    }
    &-footer {
      flex: 0 0 auto;
      padding: 24px;
      button {
        width: 100%;
      }
    }
  }
  &-header {
    width: 100%;
    height: 64px;
    background: white;
    box-shadow: 0 4px 8px rgba(black, 0.1);
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 64px;
    right: 0;
    z-index: 2;
    @media only screen and (min-width: $large) {
      width: calc(100% - 300px);
      height: 80px;
      top: 0;
    }
    &-save {
      p {
        font-size: 12px;
        font-weight: 600;
        color: $gray-50;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }
  &-content {
    padding: 128px 0 48px 0;
    position: relative;
    z-index: 1;
    @media only screen and (min-width: $large) {
      padding: 80px 0 80px 300px;
    }
    &-wrapper {
      max-width: 864px;
      margin: 48px auto;
      padding: 0 24px;
      @media only screen and (min-width: $large) {
        margin: 64px auto;
      }
    }
    &-section {
      &-intro {
        margin-bottom: 48px;
        h2 {
          margin-bottom: 24px;
        }
        p {
          margin-bottom: 24px;
          a {
            text-decoration: underline;
            &:hover {
              opacity: 0.8;
            }
          }
          em {
            font-style: oblique;
          }
          ul {
            margin-top: 4px;
            margin-left: 24px;
          }
        }
      }
      &-footer {
        display: flex;
        border-top: 1px solid $gray-40;
        margin-top: 64x;
        padding-top: 64px;
        button {
          margin-right: 24px;
        }
      }
      &-field {
        margin-bottom: 48px;
        label {
          display: block;
        }
        h3 {
          font-weight: 600;
          margin-bottom: 4px;
        }
        p {
          margin-bottom: 12px;
        }
        .file-upload {
          &-input {
            display: flex;
            input[type="file"] {
              margin-right: 12px;
              &::file-selector-button {
                margin-right: 12px;
                background-color: $gray-20;
                font-family: inherit;
                font-size: 12px;
                font-weight: 600;
                padding: 4px 8px;
                border-radius: 4px;
                border: none;
                cursor: pointer;
                &:hover {
                  background-color: $gray-30;
                }
              }
            }
          }
          &-error, &-success {
            display: none;
            margin-top: 24px;
            padding: 20px;
            border-radius: 8px;
            p {
              margin-bottom: 0;
              strong {
                display: block;
                font-weight: 600;
              }
            }
          }
          &-error {
            background: $red-10;
            p {
              strong {
                color: $red-50;
              }
            }
          }
          &-success {
            background: $green-10;
            p {
              strong {
                color: $green-50;
              }
            }
          }
          &-button {
            margin-top: 24px;
            position: relative;
            input {
              visibility: hidden;
              position: absolute;
            }
          }
        }
      }
    }
  }
  &-loading {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $large) {
      width: calc(100% - 300px);
    }
    img {
      opacity: 0.2;
    }
  }
  &-success {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $large) {
      width: calc(100% - 300px);
    }
    &-inner {
      text-align: center;
      padding: 0 24px;
      h3 {
        margin-bottom: 8px;
      }
    }
    &-animation {
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }
  }
  .delete-modal__delete-btn{
    margin-bottom: 14px;
  }
}

</style>
